export default {
  template: '<div><slot :search="search"></slot></div>',
  data: function() {
    return {
      search: {
        metro_station: general.metro_station || { code: null }
      }
    }
  }
}
