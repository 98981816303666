export default {
  props: ['max_countdown'],
  data: function() {
    return {
      dismiss_countdown: this.max_countdown,
      saved_max_countdown: this.max_countdown
    }
  },
  methods: {
    countdown_changed: function(new_tick) {
      this.dismiss_countdown = new_tick;
    }
  },
  template: '<div><slot :saved_max_countdown= "saved_max_countdown" :dismiss_countdown="dismiss_countdown" :countdown_changed="countdown_changed"></slot></div>'
}