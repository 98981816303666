
import SelectPerson from './select-person'
export default
  name: 'subscription-form'
  props:
    action: String
    initial: Object
    exists: Boolean
    currentAthlete: Object
  components: {SelectPerson}
  mounted: ->
    @$set(@, 'subs', Object.assign({}, @initial))
  data: ->
    subs: {}
    showAlert: false
    errors: []
    busy: false
  methods:
    submit: ->
      @$set(@, 'busy', true)
      @axios.post(@action, {subscriptions_subscription: @subs})
        .then (res) =>
          @showAlert = false
          @$set(@, 'busy', false)
          if res.headers.location?
              window.location.href = res.headers.location
        .catch (e) =>
          @$set(@, 'busy', false)
          if 422 == e.response.status
              @errors =  e.response.data
              @showAlert = true
          else console.error(e)


