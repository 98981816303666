import 'babel-polyfill';
import './styles.scss';
import Rails from 'rails-ujs';

import Vue from 'vue/dist/vue.esm';

import BootstrapVue from 'bootstrap-vue'
import VueTheMask from 'vue-the-mask';
import vSelect from 'vue-select'
import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
Vue.axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
Vue.axios.defaults.headers.common['Accept'] = 'application/json';
Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
Vue.axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';


Rails.start();
window.$ = $;
Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(VCalendar, {
  locale: 'ru',
  firstDayOfWeek: 2,
  formats: {
    title: 'MMMM YYYY',
    weekdays: 'W',
    navMonths: 'MMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'YYYY-MM-DD',
    data: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD']
  }
});

//region Quill editor
Vue.component('v-select', vSelect)

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor, /* { default global options } */)
//endregion

import AbonementRaceSelectionForm from '../src/components/abonement-race-selection-form';
import ArchivedHeat from '../src/components/archived_heat';
import ButtonTo from '../src/components/button-to';
import ClinicsPage from '../src/components/clinics_page';
import CustomAlerts from '../src/components/custom_alerts';
import Event from '../src/components/event';
import EventLocationMap from "../src/components/event-location-map";
import HeatList from '../src/components/heat-list.vue';
import ProductCard from '../src/components/product_card';
import RelayTeamSelector from '../src/components/relay_team_selector';
import SearchForm from '../src/components/search_form';
import SelectHeatOwner from '../src/components/select_heat_owner';
import SelectPerson from '../src/components/select-person';
import StatusIcon from '../src/components/status-icon'
import SubscriptionForm from '../src/components/subscription-form';

import $ from 'jquery';
import { RetinaTag } from '../src/modules/retina_tag';
require("jquery")
require("@nathanvda/cocoon")

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',
    components: {
      AbonementRaceSelectionForm,
      ArchivedHeat,
      ButtonTo,
      ClinicsPage,
      CustomAlerts,
      Event,
      EventLocationMap,
      HeatList,
      ProductCard,
      RelayTeamSelector,
      SearchForm,
      SelectHeatOwner, SelectPerson,
      SubscriptionForm,
      StatusIcon,
    },
    data: () => ({
      globalVar: general.globalVar
    })
  });

  new RetinaTag();
});

Vue.directive('init', {
  bind: function(el, binding, vnode) {
    vnode.context[binding.arg] = binding.value;
  }
});
