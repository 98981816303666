
export default
  name: 'abonement-race-selection-form'
  props:
    action: String
    races: Array
    limit: Number
  data: ->
    selectedRaceIds: []
  computed:
    raceOptions: ->
      @races.map (r) =>
        {value: r.id, text: "#{r.event_name} — #{r.name}", disabled: (@limit <= @selectedRaceIds.length && !@selectedRaceIds.includes(r.id))}

