
import axios from 'axios'
import VueTypeahead from 'vue2-typeahead'
import _ from 'underscore';

export default
  data: ->
    athlete_id: null
    selected_user: null
    kind: 'self'
  methods:
    clear: ->
      @athlete_id = null
      @selected_user = null
      @query = 'test'
      @athletes = []
    change_to: (variant) ->
      @athlete_id = null
      @selected_user = null
      @query = ''
      @athletes = []
      @kind = variant
    search_users: (res) ->
      @kind = 'new_athlete' if 0 == res.data.athletes.length
      res.data.athletes
    fetch: (url) -> axios.get("#{API_ENDPOINT}#{url}")
    highlighting: (user, vue) -> "#{user.name} / #{user.birthday_year}".replace(vue.query, "<b>#{vue.query}</b>")
    select_user: (new_user, vue, index) ->
      @athlete_id = new_user.id
      @selected_user = new_user
      vue.query ="#{new_user.name} / #{new_user.birthday_year}"
  components:
    'vue-typeahead': VueTypeahead
