
export default
  name: 'button-to'
  props:
    remote:
      type: Boolean
      default: false
    href:
      type: String
      default: null
    variant:
      type: String
      default: null
    size:
      type: String
      default: null
    confirm:
      type: String
      default: null
    icon:
      type: String
      default: null
    iconClass:
      type: String
      default: null
    method:
      type: String
      default: 'get'
    data:
      type: Object
      default: null

  computed:
    iconProp: ->
      if @loading
        'fa-spinner fa-pulse'
      else if @icon?
        if @iconClass?
          "fa-#{@icon} #{@iconClass}"
        else
          "fa-#{@icon}"
  data: ->
    loading: false
  methods:
    onClick: ->
      proceed = true
      if @confirm?
        proceed = confirm(@confirm)

      if proceed
        if @remote || 'post' == @method || 'patch' == @method || 'delete' == @method
          @loading = true
          @axios(method: @method, url: @href, data: @data)
            .then (res) =>
              @$emit('update', res.data)
              @loading = false
            .catch (e) =>
              @loading = false
              if 422 == e.response.status
                @$emit('error', e.response.data)
                @showAlert = true
              else console.log e
        else
          window.location.href = @href

