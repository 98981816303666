
import AthleteForm from './athlete-form'
import VueTypeahead from 'vue2-typeahead'

export default
  name: 'select-person'
  props:
    currentAthleteId: Number
    exists: Boolean
    initial: Object
    value: Number
  components: {AthleteForm, VueTypeahead}
  mounted: ->
    @$set(@, 'athlete', @initial)
    @$set(@, 'kind', 'other') if @exists
  data: ->
    query: ''
    kind: 'self'
    options: [
      {text: 'себя', value: 'self', disabled: @exists},
      {text: 'другого человека', value: 'other'},
    ]
    showAthleteForm: false
    showSearchBar: true
    athlete: {}
  methods:
    toggleShow: ->
      @$set(@, 'showAthleteForm', !@showAthleteForm)
      @$set(@, 'showSearchBar', !@showSearchBar)
    searchAthletes: (res) ->
      @toggleShow() if 0 == res.data.athletes.length
      res.data.athletes
    fetch: (url) -> @axios.get("#{API_ENDPOINT}#{url}")
    highlighting: (user, vue) -> "#{user.name} / #{user.birthday_year}".replace(vue.query, "<b>#{vue.query}</b>")
    updateAthlete: (athlete) ->
      @$set(@, 'athlete', Object.assign({}, athlete))
      @showAthleteForm = false
    selectAthlete: (newAthlete, vue, index) ->
      @$set(@, 'athlete', Object.assign({}, newAthlete))
      @showSearchBar = false
      vue.query = "#{newAthlete.name} / #{newAthlete.birthday_year}"
  watch:
    'athlete.id': ->
      @$emit('input', @athlete.id)
    kind: (newVal, oldVal) ->
      if 'self' == newVal
        @$emit('input', @currentAthleteId)
      else
        if @currentAthleteId == @athlete.id
          @$emit('input', null)
        else
          @$emit('input', @athlete.id)

