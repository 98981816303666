
export default
  name: 'status-icon'
  props:
    status:
      type: [String, Number ]
      required: false
    size:
      type: String
      required: false
  computed:
    hasSlot: -> !!@$slots.default
    iconSize: ->
      if @size?
        ' fa-' + @size
      else
        ''

  methods:
    cssClass: (status) ->
      @icon(status) + ' ' + @variant(status) + @iconSize
    icon: (status) ->
      switch status
        when 'created', 'initial', 'inactive', 'waiting', 'initialized' then 'fal fa-snooze'
        when 'active', 'ready', 'true', 'successful', 'accepted', 'processed' then 'far fa-check-circle'
        when 'open' then 'far fa-play-circle'
        when 'closed' then 'far fa-stop-circle'
        when 'finished' then 'fal fa-flag-checkered'
        when 'paid', 'complete' then 'fa-check'
        when 'unpaid' then 'fal fa-exclamation-circle'
        when 'error', 'failed' then 'fa-exclamation-triangle'
        when 'planned', 'frozen' then 'far fa-pause-circle'
        when 'delivered' then 'fal fa-pause-circle'
        when 'locked', 'private', 'off' then 'fal fa-lock'
        when 'refunded', 'reversed' then 'fal fa-undo-alt'
        when 'partial_refunded' then 'fal fa-undo-alt'
        when 'transferred' then 'fal fa-exchange'
        when 'cancelled', 'false', 'rejected' then 'far fa-times-circle'
        when 'archived' then 'fal fa-archive'
        when 'male', 'M' then 'fal fa-mars'
        when 'female', 'F' then 'fal fa-venus'
        when 'mixed', 'X'  then 'fal fa-venus-mars'
        when 'unknown'  then 'fal fa-genderless'
        when 'public', 'on' then 'fal fa-eye'
        when 'hidden', 'unlisted', 'invisible' then 'fal fa-eye-slash'
        else
          'fal fa-question-square'
    variant: (status) ->
      switch status
        when 'active', 'ready', 'open', 'finished', 'refunded', 'public', 'paid', 'complete', 'on', 'true', 'successful', 'accepted', 'processed' then 'text-success'
        when 'unpaid', 'delivered' then 'text-warning'
        when 'error', 'failed', 'rejected' then 'text-danger'
        when 'created', 'initial', 'inactive', 'closed', 'transferred', 'unlisted', 'hidden', 'waiting', 'invisible' then 'text-info'
        when 'cancelled', 'archived', 'planned', 'frozen', 'private', 'off', 'false', 'none' then 'text-secondary'
        else
          ''

