export default {
  props: ['state'],
  mounted: function() {
    this.toggle.not_for_self = 'true' == this.state
  },
  template: '<div><slot :toggle="toggle"></slot></div>',
  data: function() {
    return {
      toggle: {
        not_for_self: false
      }
    }
  }
}