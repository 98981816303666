export default {
  props: ['state'],
  mounted: function() {
    this.toggle.state = 'false' != this.state;
  },
  template: '<div><slot :toggle="toggle"></slot></div>',
  data: function() {
    return {
      toggle: {
        state: false,
        go: function() {
          this.state = !this.state;
        }
      }
    }
  }
}