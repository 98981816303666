
import {latLng} from 'leaflet/dist/leaflet'
import {LMap, LMarker, LTileLayer, LTooltip} from 'vue2-leaflet'

import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

import 'leaflet/dist/leaflet.css'


export default
  name: 'event-location-map'
  props: ['lat', 'lng', 'address']
  components: {LMap, LMarker, LTileLayer, LTooltip}
  computed:
    center: -> latLng(@lat, @lng)

