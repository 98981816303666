export default {
  props: ['state'],
  mounted: function() {
    this.toggle.state = 'false' != this.state;
  },
  template: '<div><slot :toggle="toggle"></slot></div>',
  data: function() {
    return {
      toggle: {
        change_race_for: function(heat_id) {
          if ( confirm('Вы уверены, что хотите изменить дистанцию?') ) {
            $('#edit_heat_' + heat_id.toString()).submit();
          }
        },
        state: false,
        go: function() {
          this.state = !this.state;
        }
      }
    }
  }
}