
export default
  name: 'athlete-form'
  props:
    initial: {type: String, default: ''}
  data: ->
    athlete: {
      name_last: '',
      name_first: '',
      name_middle: '',
      birth_date: '',
      gender: null,
      phone: '',
      email: ''
    }
    genders: [
      {text: 'Мужской', value: 'male'},
      {text: 'Женский', value: 'female'},
    ]
    showAlert: false
    errors: []
  mounted: ->
    [@athlete.name_last, @athlete.name_first, @athlete.name_middle ]= @initial.split(' ', 3)
    @$refs.nameLast.focus()
  methods:
    hideSelf: ->
      @$emit('reset')
    save: ->
      @axios.post("#{API_ENDPOINT}/athletes", {athlete: @athlete})
        .then (res) =>
          @showAlert = false
          @$emit('input', Object.assign({}, res.data.athlete))
        .catch (e) =>
          if 422 == e.response.status
              @errors =  e.response.data
              @showAlert = true
          else console.error(e)

