<template lang="pug">
  div
    h3
      | Состав команды&nbsp;
      span(v-if="min != max") (от {{ min }} до {{ max }} чел.)
      span(v-else) ({{ max }} чел.)
    p Используйте кнопку «Добавить» для добавления еще одного участника команды. Себя добавлять не нужно.

    div(v-for="(athlete, index) in athletes" v-bind:key="index")
      h3.mt-4
        | {{ index + 2 }}-й участник&nbsp;
        i.fa.fa-times.text-danger.pointer(@click="remove_athlete(index)")

      v-select(
        label="name"
        :clearable="false"
        :options="found"
        v-model="athletes[index]"
        @search="on_search"
        @input="on_select"

      )

        template(slot="selected-option" slot-scope="option")
          span(v-if="undefined != option.id")
            strong {{ option.name }}
            | &nbsp;/ {{ option.birthday_year }}
        template(slot="no-options") Начните вводить ФИО участника.
        template(slot="option" slot-scope="option")
          strong {{ option.name }}
          | &nbsp;/ {{ option.birthday_year }}
      input.hidden(type="text" v-model="athlete.id" name="heat[relay_athlete_ids][]")

    p.mt-4(v-if="athletes.length + 1 < max")
      span.btn.btn-success(@click="add_athlete") Добавить участника

</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
  beforeMount: function() {
    let last = this.min == this.max ? this.max : this.min
    last = last - this.athletes.length

    for (let i = 2; i <= last; i++) {
      this.athletes.push({ id: null, name: null })
    }
  },
  data: function() {
    return {
      selected: null,
      found: [],
      min: general.min_relay_team_size,
      max: general.max_relay_team_size,
      athletes: general.relay_athletes || []

    }
  },
  methods: {
    on_select: function(value) {
      this.found = []
    },
    on_search: function(query, loading) {
      if ( query.length > 2 ) {
        loading(true)
        return this.search(loading, query, this);
      }
    },
    search: _.debounce((loading, query, vm) => {
      fetch(API_ENDPOINT + "/athletes/search?query=" + query)
        .then(res => {

          res.json().then(function(json) {
            vm.found = json.athletes
          })

          loading(false)
        });
    }, 500),
    remove_athlete: function(index) {
      if ( this.athletes.length < this.min ) {
        alert('У вас неполная команда!')
      } else {
        this.athletes.splice(index, 1)
      }
    },
    add_athlete: function() {
      if ( this.athletes.length + 1 < this.max ) {
        this.athletes.push({ id: null, name: null })
      } else {
        alert('В команде не может быть больше людей!')
      }
    }
  }
}
</script>

<style>
  .pointer { cursor: pointer; }
</style>
