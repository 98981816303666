export default {
  template: '<div><slot :form="form"></slot></div>',
  watch: {
    'form.distance': {
      handler: function() {
        document.getElementById('new_q').submit()
      }
    },
    'form.sport_id': {
      handler: function() {
        document.getElementById('new_q').submit()
      }
    }
  },
  data: function() {
    return {
      form: {
        dates: {
          start: general.search_form.start,
          end: general.search_form.end
        },
        distance: general.search_form.distance,
        sport_id: general.search_form.sport_id,
        text: general.search_form.text
      }
    }
  }
}